import localClient from './localClient';

export class Logger {
  static instance: Logger | null = null;
  sdk: any;

  constructor(sdk: any) {
    if (Logger.instance) {
      return Logger.instance;
    }

    this.sdk = sdk;
    Logger.instance = this;
  }

  static initialize(sdk: any): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger(sdk);
    }
    return Logger.instance;
  }

  static getInstance(): Logger {
    if (!Logger.instance) {
      throw new Error('Logger is not initialized. Call Logger.initialize(sdk) first.');
    }
    return Logger.instance;
  }

  private formatData(level: string, data: any) {
    return {
      level,
      metadata: {
        ...(data?.metadata ?? {}),
        from: 'frontend',
      },
      entryId: this.sdk.ids.entry,
      spaceId: this.sdk.ids.space,
      ...data,
    };
  }

  info(data: any) {
    return localClient.logActivity(this.formatData('INFO', data));
  }

  warn(data: any) {
    return localClient.logActivity(this.formatData('WARN', data));
  }

  error(data: any) {
    return localClient.logActivity(this.formatData('ERROR', data));
  }
}
