import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { FormControl, Select } from '@contentful/f36-components';
import { ENTRY_LEVEL, localizationMethods } from '../../../config/translationModel';
import { isMediaAsset } from '../../../utils/assetHelpers';
import { Logger } from '../../../utils/logger';

interface Props {
  sdk: EditorExtensionSDK;
  value: string;
  handleFieldChange: Function;
}

interface State {
  options: object[];
  validationMessage: string;
}

export default class LocalizationMethodField extends React.Component<Props, State> {
  shouldLoadDefault: boolean;
  state: State = {
    options: [],
    validationMessage: '',
  };

  async componentDidMount() {
    await this.handleMount();
  }

  constructor(props: Readonly<Props>) {
    super(props);
    this.shouldLoadDefault = !this.props.value;
    let fieldValue = !this.props.value ? 'field-level' : this.props.value;
    //@ts-ignore
    const defaultLocalizationMethod =
      this.props.sdk.parameters.installation.defaultLocalizationMethod;
    const localizationMethod =
      defaultLocalizationMethod && this.shouldLoadDefault ? defaultLocalizationMethod : fieldValue;

    this.props.handleFieldChange('localizationMethod', localizationMethod);
  }

  handleMount = async () => {};

  render() {
    return (
      <FormControl isRequired>
        <FormControl.Label>Localization Method</FormControl.Label>
        <Select
          className=""
          id="localizationMethod"
          name="localizationMethod"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            Logger.getInstance().info({
              event: `LocalizationMethod field changed`,
              action: `LocalizationMethod set to [${e.target.value}]`,
            });
            this.props.handleFieldChange('localizationMethod', e.target.value);
          }}
          testId="cf-ui-select-field"
          value={this.props.value}
        >
          <Select.Option value="">Select Localization Method</Select.Option>
          {localizationMethods
            .filter((option) => {
              return !(
                isMediaAsset(this.props.sdk.entry.fields.contentType.getValue()) &&
                option.id === ENTRY_LEVEL
              );
            })
            .map((option: any) => {
              return (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              );
            })}
        </Select>
        {this.state.validationMessage && (
          <FormControl.ValidationMessage>
            {this.state.validationMessage}
          </FormControl.ValidationMessage>
        )}
      </FormControl>
    );
  }
}
