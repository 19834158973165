import React from 'react';
import { PageExtensionSDK } from '../../../extensions-sdk';
import InsightBox from './InsightBox';
import milify from 'millify';
import localClient from '../../../utils/localClient';
import { getAllEntries } from '../../../utils/helpers';

interface Props {
  sdk: PageExtensionSDK;
  divider: boolean;
}

interface State {
  wordCount: number;
  loading: boolean;
}

export default class TotalWords extends React.Component<Props, State> {
  state: State = {
    wordCount: 0,
    loading: true,
  };

  async componentDidMount() {
    const { sdk } = this.props;
    //@ts-ignore
    const translationProjectContentType = sdk.parameters.installation.translationsModelId;
    // get all translation projects in the space
    const query = {
      content_type: translationProjectContentType,
    };
    const translationProjectIds: string[] = (await getAllEntries(sdk, 0, query)).map(
      (e: any) => e.sys.id,
    );

    let translatedEntriesInfo = await localClient.getTranslatedEntriesInfo({
      translationProjectIds,
    });

    this.setState({
      wordCount: translatedEntriesInfo.wordCount,
      loading: false,
    });
  }

  render() {
    let words = milify(this.state.wordCount, { precision: 2 });
    return (
      <InsightBox
        stat={words}
        text={'Words Translated'}
        divider={this.props.divider}
        loading={this.state.loading}
      />
    );
  }
}
