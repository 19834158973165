import React from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  SkeletonRow,
  Badge,
  HelpText,
  TextLink,
} from '@contentful/f36-components';
import loGet from 'lodash.get';
import { PageExtensionSDK } from '../../../extensions-sdk';
import wordCount from '../../../../../lambda/src/utils/words-count';
import { getFlagImage, getLangCodeInfo } from '../icons';
import { sessionStore } from '../../../utils/SessionStore';
import DatamapComponent from './DatamapComponent';
import { getContentTypeFromEntry } from '../../../utils/contentful';
import { getContentTypes, getSessionStoreKey, getAllEntries } from '../../../utils/helpers';
import { getAllAssets } from '../../../utils/assetHelpers';
const fillColors = [
  '#BBD3F1',
  '#AAC8EE',
  '#99BCEA',
  '#88B1E7',
  '#78A6E3',
  '#679BE0',
  '#5690DC',
  '#4585D9',
  '#2E75D4',
  '#2A70CB',
  '#2666BA',
  '#235DA9',
  '#1F5498',
  '#1C4A87',
  '#184177',
  '#153866',
  '#112F55',
  '#0E2544',
  '#0A1C33',
  '#071322',
];

interface Props {
  sdk: PageExtensionSDK;
}

interface Locale {
  name: string;
  words: number;
  country: string;
  totalFields: number;
  totalFilledFields: number;
}

interface VisibleLocales {
  [key: string]: Locale;
}

interface State {
  loading: boolean;
  visibleLocales: VisibleLocales;
  itemsVisible: number;
  class: string;
}

export default class LanguageCoverage extends React.Component<Props, State> {
  state: State = {
    loading: true,
    visibleLocales: {},
    itemsVisible: 10,
    class: 'map-container',
  };

  async componentDidMount() {
    await this.updateVisibleLanguages();
    this.setState({
      loading: false,
    });
  }

  loadMore = () => {
    this.setState(
      {
        itemsVisible: this.state.itemsVisible + 5,
      },
      () => {
        this.updateVisibleLanguages();
      },
    );
  };

  async updateVisibleLanguages() {
    this.setState({
      loading: true,
    });
    const { sdk } = this.props;
    const storeKey = getSessionStoreKey('visible_locales', sdk, this.state.itemsVisible);
    let visibleLocales: VisibleLocales = {};
    if (!sessionStore.get(storeKey)) {
      //@ts-ignore
      const translationProjectContentType = sdk.parameters.installation.translationsModelId;
      //@ts-ignore
      const translatorContentType = sdk.parameters.installation.translatorModelId;
      const available = [...sdk.locales.available];
      available.sort((item) => {
        if (item == sdk.locales.default) {
          return -1;
        }
        return 0;
      });
      const locales = available.filter((lang: string, index) => {
        return index < this.state.itemsVisible;
      });
      for (let locale of locales) {
        visibleLocales[locale] = {
          name: sdk.locales.names[locale],
          country: 'US',
          words: 0,
          totalFields: 0,
          totalFilledFields: 0,
        };
      }
      const contentTypes: any[] = await getContentTypes(sdk);
      const allEntries = await getAllEntries(sdk);
      const allAssets = await getAllAssets(sdk);
      const fullEntries: any[] = [];
      fullEntries.push(...allEntries);
      fullEntries.push(...allAssets);

      for (let contentType of contentTypes) {
        let localizedFields = contentType.fields.filter((field: { localized: number }) => {
          return field.localized;
        });
        if (
          [translationProjectContentType, translatorContentType].includes(contentType.sys.id) ||
          localizedFields.length < 1
        ) {
          continue;
        }

        let entries: any[] = fullEntries.filter((e: any) => {
          return contentType.sys.id == getContentTypeFromEntry(e);
        });

        for (let locale of locales) {
          for (let entry of entries) {
            let words = this.getWordsForEntry(entry, locale);
            for (let field of localizedFields) {
              visibleLocales[locale].totalFields++;
              let fieldData = loGet(entry, `fields.${field.id}.${locale}`);
              if (fieldData != undefined && fieldData != '') {
                visibleLocales[locale].totalFilledFields++;
              }
            }
            visibleLocales[locale].words += words;
          }
        }
      }
      sessionStore.set(storeKey, visibleLocales);
    } else {
      visibleLocales = sessionStore.get(storeKey);
    }

    this.setState({
      visibleLocales,
      loading: false,
    });
  }

  render() {
    let visibleLocales: any = [];
    let defaultLocale = this.props.sdk.locales.default;
    const totalSourceFields = this.state.visibleLocales[defaultLocale]
      ? this.state.visibleLocales[defaultLocale].totalFilledFields
      : 0;

    let fillKeys: any = { defaultFill: '#D6D8E0' };
    for (let i = 0; i < fillColors.length; i++) {
      fillKeys[i] = fillColors[i];
    }
    let data: any = {};
    for (let localeName in this.state.visibleLocales) {
      let locale = this.state.visibleLocales[localeName];
      let code: any = getLangCodeInfo(localeName);
      let coverage = Math.round((locale.totalFilledFields / totalSourceFields) * 100);
      let fillKey = Math.floor(coverage / (100 / fillColors.length));
      let alphaCode = code['code3'];
      if (alphaCode != '' && alphaCode != null) {
        data[alphaCode] = {
          fillKey,
          coverage:
            data[alphaCode] && data[alphaCode].coverage
              ? Math.round((data[alphaCode].coverage + coverage) / 2)
              : coverage,
          locales:
            data[alphaCode] && data[alphaCode].locales
              ? [...data[alphaCode].locales, locale.name]
              : [locale.name],
          country: code['country'],
        };
      }

      visibleLocales.push(
        <TableRow key={locale.name}>
          <TableCell className={'flex-container'}>
            <img src={getFlagImage(code['flag'])} className={'coverage-icon'} />
            {locale.name}{' '}
            {localeName == defaultLocale && <Badge className={'lang-tag'}>Default</Badge>}
          </TableCell>
          <TableCell>{locale.words}</TableCell>
          <TableCell>{code['country'] || '-'}</TableCell>
          <TableCell>{coverage}%</TableCell>
        </TableRow>,
      );
    }
    return (
      <>
        <h4 className={'flex-container space-between'}>
          <div>Languages</div>
          <div>
            <TextLink
              onClick={() => {
                this.setState({
                  class: this.state.class == 'map-container' ? 'hide-trans' : 'map-container',
                });
              }}
            >
              {this.state.class == 'map-container' ? 'Hide Map' : 'Show map'}
            </TextLink>
          </div>
        </h4>
        <div className={this.state.class}>
          {
            <DatamapComponent
              fills={fillKeys}
              geographyConfig={{
                popupTemplate: (geography: any, data: any) => {
                  return (
                    '<b>Country:</b> ' +
                    data.country +
                    '<br> <b>Locales:</b> ' +
                    data.locales.join(', ') +
                    '<br> <b>Coverage:</b> ' +
                    data.coverage +
                    '%'
                  );
                },
                highlightFillColor: '#8091a5',
                highlightBorderOpacity: 0,
              }}
              data={data}
            />
          }
        </div>
        <div className={'load-more-container'}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Locale</TableCell>
                <TableCell>Total Words</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Coverage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.loading ? (
                <SkeletonRow rowCount={3} columnCount={4} />
              ) : !this.state.loading && !visibleLocales.length ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Nothing here</TableCell>
                </TableRow>
              ) : (
                <>{visibleLocales}</>
              )}
            </TableBody>
          </Table>
          {!this.state.loading &&
            this.state.itemsVisible < this.props.sdk.locales.available.length && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                <TextLink className={'load-more'} variant={'muted'} onClick={this.loadMore}>
                  Load More
                </TextLink>
              </div>
            )}
          <HelpText style={{ fontSize: 'smaller', padding: '8px 0px' }}>
            Icons made by{' '}
            <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
              Freepik
            </a>{' '}
            from{' '}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </HelpText>
        </div>
      </>
    );
  }

  private getWordsForEntry(entry: any, fieldLocale: string | null = null) {
    let words = 0;
    let fields = entry.fields;
    let locale = fieldLocale || this.props.sdk.locales.default;

    for (let fieldName in fields) {
      if (
        loGet(fields, `${fieldName}.${locale}.0.sys.type`, false) ||
        loGet(fields, `${fieldName}.${locale}.sys.type`, false)
      ) {
        // skipping reference links
      } else if (Array.isArray(loGet(fields, `${fieldName}.${locale}`, null))) {
        words += wordCount(loGet(fields, `${fieldName}.${locale}`, []).join(' '));
      } else {
        words += wordCount(fields[fieldName][locale]);
      }
    }

    return words;
  }
}
