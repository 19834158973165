import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { FormControl, Select } from '@contentful/f36-components';
import { Programs } from '../parts/AdvanceOptions';
import { Logger } from '../../../utils/logger';

interface Props {
  sdk: EditorExtensionSDK;
  handleFieldChange: Function;
  allPrograms: Programs[];
  selectedProgram: any;
}

export default class ProgramSelector extends React.Component<Props> {
  handleFieldChange = (key: string, value: any) => {
    this.props.handleFieldChange(key, parseInt(value, 10));
  };

  render() {
    const { selectedProgram, allPrograms } = this.props;
    const translationInfo = this.props.sdk.entry.fields['translationInfo'].getValue();
    const sdkSelectedProgram = this.props.sdk.entry.fields['selectedProgram'].getValue();
    const isFieldDisabled =
      translationInfo !== undefined &&
      translationInfo?.programSelectionPreferences &&
      translationInfo?.programSelectionPreferences?.selectedProgram !== null;
    return (
      <FormControl isRequired className="advance-options">
        <FormControl.Label> Program </FormControl.Label>
        <Select
          id="ProgramSelection"
          name="ProgramSelection"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            Logger.getInstance().info({
              event: `selectedProgram field changed`,
              action: `selectedProgram set to [${e.target.value}]`,
            });
            this.handleFieldChange('selectedProgram', e.target.value);
          }}
          isDisabled={isFieldDisabled}
          value={
            sdkSelectedProgram !== null && sdkSelectedProgram !== undefined
              ? sdkSelectedProgram
              : selectedProgram
          }
          testId="cf-ui-select-field"
        >
          <Select.Option value={0}>Select a program</Select.Option>
          {allPrograms.map((option: any) => {
            return (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}
