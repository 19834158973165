import React from 'react';
import { PageExtensionSDK } from '../../extensions-sdk';
import {
  Card,
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  SkeletonRow,
  TableRow,
  Checkbox,
  Badge,
  TextLink,
} from '@contentful/f36-components';
import { getStatusColor, getStatusBackgroundColor } from '../../config/translationModel';
import { Row, Col } from 'react-grid-system';
import { getContentTypeFromEntry, getDisplayField, getEntryStatus } from '../../utils/contentful';
import loGet from 'lodash.get';
import {
  getContentTypes,
  isTypeEntry,
  TYPE_ENTRY,
  getAllEntries,
  dateTimeFormat,
} from '../../utils/helpers';
import {
  getAllAssets,
  isMediaAsset,
  isMediaLinkType,
  MEDIA_ASSETS_ID,
  MEDIA_LINK_TYPE,
} from '../../utils/assetHelpers';

interface Props {
  sdk: PageExtensionSDK;
}

interface State {
  loading: boolean;
  allContentTypes: any;
  entries: any;
  visibleEntries: any;
  selectedEntries: string[];
  itemsVisible: number;
}

/**
 * TODO: Ideally the load more can trigger another request to check for more entries (we are fetching only 100)
 * This can be done by storing the total number of entries retrieved per content type.
 */
export default class NewEntries extends React.Component<Props, State> {
  state: State = {
    loading: true,
    allContentTypes: [],
    entries: [],
    selectedEntries: [],
    itemsVisible: 10,
    visibleEntries: [],
  };

  async componentDidMount() {
    const { sdk } = this.props;
    const contentTypes: any[] = await getContentTypes(sdk);
    //@ts-ignore
    const translationProjectContentType = sdk.parameters.installation.translationsModelId;

    const defaultLocale = sdk.locales.default;
    const query = {
      content_type: translationProjectContentType,
      'sys.publishedAt[exists]': true,
    };
    let translationProjects: any = await getAllEntries(sdk, 0, query);

    let sourceContentIds = translationProjects.reduce((acc: any, translationProject: any) => {
      return [
        ...acc,
        ...loGet(
          translationProject,
          `fields.translationInfo.${defaultLocale}.entry.submittedEntryIds`,
          [],
        ),
      ];
    }, []);

    let entries: any;

    const allEntries: any = await this.getEntriesOrAssets(
      sdk,
      contentTypes,
      sourceContentIds,
      TYPE_ENTRY,
      translationProjectContentType,
    );
    const allAssets: any = await this.getEntriesOrAssets(
      sdk,
      contentTypes,
      sourceContentIds,
      MEDIA_LINK_TYPE,
      translationProjectContentType,
    );
    let mergeAssetsAndEntries: any[] = [];
    // Merge allEntries && allAssets w.r.t updatedAt
    while (allEntries.length && allAssets.length) {
      let entryCreatedAt = new Date(allEntries[0].sys.updatedAt).getTime();
      let assetCreatedAt = new Date(allAssets[0].sys.updatedAt).getTime();
      if (entryCreatedAt >= assetCreatedAt) {
        mergeAssetsAndEntries.push(allEntries.shift());
      } else {
        mergeAssetsAndEntries.push(allAssets.shift());
      }
    }
    mergeAssetsAndEntries = mergeAssetsAndEntries.concat(allEntries);
    mergeAssetsAndEntries = mergeAssetsAndEntries.concat(allAssets);
    this.setState(
      {
        allContentTypes: contentTypes,
        entries: mergeAssetsAndEntries,
        loading: false,
      },
      () => {
        this.setVisibleEntries();
      },
    );
  }

  getEntriesOrAssets = async (
    sdk: PageExtensionSDK,
    contentTypes: any[],
    sourceContentIds: any[],
    entryOrAsset: string,
    translationProjectContentType: string,
  ) => {
    let entriesOrAsset: any = [];
    //@ts-ignore
    const translatorContentType = sdk.parameters.installation.translatorModelId;
    const query = {
      order: '-sys.createdAt',
    };

    let entryData: any;
    if (isTypeEntry(entryOrAsset)) {
      entryData = await getAllEntries(sdk, 100, query);
    } else {
      entryData = await getAllAssets(sdk, 100, query);
    }

    for (let entry of entryData) {
      let contentType: any = contentTypes.find((ct) => {
        return (
          ct.sys.id == (isTypeEntry(entryOrAsset) ? entry.sys.contentType.sys.id : MEDIA_ASSETS_ID)
        );
      });
      let localizedFields = contentType.fields.filter((field: { localized: number }) => {
        return field.localized;
      });
      if (
        ([translationProjectContentType, translatorContentType].includes(
          entry?.sys?.contentType?.sys?.id,
        ) ||
          localizedFields.length < 1) &&
        !isMediaLinkType(entry?.sys?.type)
      ) {
        continue;
      }
      if (sourceContentIds.includes(entry.sys.id)) {
        continue;
      }
      entriesOrAsset.push(entry);
      if (entriesOrAsset.length > 100) {
        break;
      }
    }

    return entriesOrAsset;
  };

  setVisibleEntries = () => {
    let visibleEntries = this.state.entries.filter((value: any, index: number) => {
      return index < this.state.itemsVisible;
    });
    this.setState({
      visibleEntries,
    });
  };

  updateSelectedEntries = (value: string, all = false) => {
    this.setState((state) => {
      const entries = state.visibleEntries;
      let selections = state.selectedEntries;
      if (all) {
        if (selections.length === entries.length) {
          selections = [];
        } else {
          selections = entries.map((entry: any) => entry.sys.id);
        }
      } else {
        const index = selections.indexOf(value);
        if (index === -1) {
          selections.push(value);
        } else {
          selections.splice(index, 1);
        }
      }
      return {
        selectedEntries: selections,
      };
    });
  };

  getContentType = (selectedEntry: any) => {
    const contentType: any =
      this.state.allContentTypes.find((contentType: any) => {
        return contentType.sys.id === getContentTypeFromEntry(selectedEntry);
      }) || {};
    return contentType.name;
  };

  loadMore = () => {
    this.setState(
      {
        itemsVisible: this.state.itemsVisible + 5,
      },
      () => {
        this.setVisibleEntries();
      },
    );
  };

  createProject = async () => {
    const { sdk } = this.props;
    const { selectedEntries } = this.state;
    // check if all the selected entries are of same type
    let contentType = '';
    let allSameType = selectedEntries.every((selectedEntryId: string) => {
      let entry = this.state.entries.find((entry: any) => {
        return entry.sys.id == selectedEntryId;
      });
      let entryContentType: any = isMediaLinkType(entry?.sys?.type)
        ? MEDIA_ASSETS_ID
        : entry.sys.contentType.sys.id;

      if (contentType == '') {
        contentType = entryContentType;
      }
      return contentType === entryContentType;
    });
    if (!allSameType) {
      sdk.notifier.error('Select entries of the same content type to create a new project');
      return false;
    }
    this.setState({
      loading: true,
    });
    //@ts-ignore
    const translationProjectContentType = sdk.parameters.installation.translationsModelId;
    try {
      let linkType = isMediaAsset(contentType) ? MEDIA_LINK_TYPE : TYPE_ENTRY;
      let entry: any = await sdk.cma.entry.create(
        { contentTypeId: translationProjectContentType },
        {
          fields: {
            contentType: {
              [sdk.locales.default]: contentType,
            },
            sourceContent: {
              [sdk.locales.default]: selectedEntries.map((selectedEntry: string) => {
                return {
                  sys: {
                    id: selectedEntry,
                    linkType: linkType,
                    type: 'Link',
                  },
                };
              }),
            },
          },
        },
      );
      await sdk.navigator.openEntry(entry.sys.id);
    } catch (Error) {
      sdk.notifier.error('There was an error creating the entry. Please try again.');
    }
    this.setState({
      loading: false,
    });
  };

  render() {
    const { visibleEntries, selectedEntries } = this.state;
    return (
      <Card padding="default" testId="cf-ui-card" title="New Entries" style={{ marginTop: '2em' }}>
        <Row>
          <Col xs={8}>
            <h3>New Source Content</h3>
          </Col>
          <Col xs={4} className={'pull-to-right'}>
            <Button
              variant="primary"
              isDisabled={!this.state.selectedEntries.length || this.state.loading}
              isLoading={this.state.loading}
              onClick={this.createProject}
            >
              Create translation project
            </Button>
          </Col>
        </Row>
        <div className={'load-more-container'}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {visibleEntries.length > 0 && (
                    <Checkbox
                      isChecked={selectedEntries.length == visibleEntries.length}
                      onChange={(evt) => {
                        this.updateSelectedEntries(evt.target.value, true);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Content Type</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.loading ? (
                <SkeletonRow rowCount={4} columnCount={4} />
              ) : !this.state.loading && !visibleEntries.length ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Nothing here</TableCell>
                </TableRow>
              ) : (
                <>
                  {visibleEntries.map((entry: any) => {
                    const entryName = getDisplayField(
                      entry,
                      this.state.allContentTypes,
                      this.props.sdk.locales.default,
                    );
                    let entryStatus: string = getEntryStatus(entry.sys) ?? '';
                    return (
                      <TableRow
                        key={entry.sys.id}
                        className={
                          selectedEntries.includes(entry.sys.id) ? 'table-row-selected' : ''
                        }
                      >
                        <TableCell>
                          <Checkbox
                            value={entry.entryId}
                            isChecked={selectedEntries.includes(entry.sys.id)}
                            onChange={() => {
                              this.updateSelectedEntries(entry.sys.id);
                            }}
                          />
                        </TableCell>
                        <TableCell>{entryName}</TableCell>
                        <TableCell>{this.getContentType(entry)}</TableCell>
                        <TableCell>{dateTimeFormat(new Date(entry.sys.createdAt))}</TableCell>
                        <TableCell>
                          <Badge
                            className=""
                            style={{
                              color: getStatusColor(entryStatus),
                              backgroundColor: getStatusBackgroundColor(entryStatus),
                            }}
                            variant="positive"
                            testId="cf-ui-tag"
                          >
                            {entryStatus}
                          </Badge>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
          {!this.state.loading && this.state.itemsVisible < this.state.entries.length && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
              <TextLink className={'load-more'} variant={'muted'} onClick={this.loadMore}>
                Load More
              </TextLink>
            </div>
          )}
        </div>
      </Card>
    );
  }
}
