import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../extensions-sdk';
import { Form, TextInput, Select, Switch, FormControl } from '@contentful/f36-components';
import { OPTION_ACCLARO, OPTION_GOOGLE, translatorOptions } from '../../config/translatorModel';
import loGet from 'lodash.get';
import { encryptApiKey, decryptApiKey } from '../../utils/helpers';
import LoadingOverlay from 'react-loading-overlay-ts';
import MigrationHelper from '../../utils/migrationHelper';

interface Props {
  sdk: EditorExtensionSDK;
}

interface State {
  name: string;
  translationService: string;
  apiKey: string;
  sandbox: boolean;
  translatorInfo: object;
  showAPIKey: boolean;
  showSandbox: boolean;
  [key: string]: any;
  programSelection: boolean;
  loadingText: string;
  loading: boolean;
}

export default class TranslatorEditor extends React.Component<Props, State> {
  state: State = {
    name: '',
    translationService: '',
    apiKey: '',
    sandbox: true,
    translatorInfo: {},
    showAPIKey: false,
    showSandbox: false,
    programSelection: false,
    loadingText: '',
    loading: false,
  };

  constructor(props: Readonly<Props>) {
    super(props);
    const { sdk } = this.props;
    // Run migration if needed
    const migration = MigrationHelper.isMigrationNeeded(sdk);
    if (migration) {
      this.state.loading = true;
      MigrationHelper.runMigration(sdk, migration, this.updateLoadingText);
    }
  }

  async componentDidMount() {
    await this.handleMount();
    this.props.sdk.entry.fields['translatorInfo'].onValueChanged(this.handleTranslatorInfoChange);
  }

  handleTranslatorInfoChange = async (value: any) => {
    this.setState({
      translatorInfo: value,
    });
  };

  updateLoadingText = (text: string) => {
    this.setState({
      loadingText: text,
    });
  };

  handleMount = async () => {
    const localState: any = {
      name: '',
      translationService: '',
      apiKey: '',
      sandbox: true,
      translatorInfo: {},
      programSelection: false,
    };
    for (let i in localState) {
      if (i === 'apiKey') {
        localState[i] = decryptApiKey(this.props.sdk.entry.fields[i].getValue());
      } else {
        localState[i] = this.props.sdk.entry.fields[i]?.getValue();
      }
    }

    this.setState(localState, () => {
      this.handleOtherFieldVisibility();
    });
  };

  updateState = async (key: string, value: any) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.handleOtherFieldVisibility();
      },
    );
    if (key === 'apiKey') {
      value = encryptApiKey(value);
    }
    await this.props.sdk.entry.fields[key]?.setValue(value);
  };

  canChangeValuesWithoutConfirmation = (key: string) => {
    return (
      (key != 'sandbox' && !this.state[key]) || !loGet(this.state, 'translatorInfo.isAuthenticated')
    );
  };

  handleFieldChange = async (key: string, value: any) => {
    if (['translationService', 'apiKey', 'sandbox', 'programSelection'].includes(key)) {
      const confirmed = this.canChangeValuesWithoutConfirmation(key)
        ? true
        : await this.props.sdk.dialogs.openConfirm({
            title: 'Are you sure?',
            message: 'You will need to reauthenticate if you change this field',
            intent: 'positive',
            confirmLabel: 'Yes!',
            cancelLabel: 'No',
          });
      if (confirmed) {
        await this.handleFieldChange('translatorInfo', {
          ...this.state.translatorInfo,
          isAuthenticated: false,
        });
        await this.updateState(key, value);
      } else {
        let value = this.state[key];
        // hack for re rendering field
        this.setState(
          (prevState) => {
            return {
              [key]: typeof prevState[key] === 'boolean' ? !prevState[key] : '',
            };
          },
          async () => {
            await this.updateState(key, value);
          },
        );
      }
    } else {
      await this.updateState(key, value);
    }
  };

  handleOtherFieldVisibility = () => {
    this.setState((prevState) => {
      return {
        showAPIKey: [OPTION_ACCLARO, OPTION_GOOGLE].includes(prevState.translationService),
        showSandbox: prevState.translationService == OPTION_ACCLARO,
      };
    });
  };

  render() {
    return (
      <>
        <LoadingOverlay active={this.state.loading} spinner text={this.state.loadingText}>
          <div className="app translator-editor">
            <div className="body no-shadow">
              <div className="config no-padding">
                <div className="section">
                  <Form onSubmit={() => console.log('onSubmit')}>
                    <FormControl isRequired>
                      <FormControl.Label>Name</FormControl.Label>
                      <TextInput
                        name="nameInput"
                        id="nameInput"
                        value={this.state.name}
                        onChange={(e) => {
                          // @ts-ignore
                          this.handleFieldChange('name', e.target.value as string);
                        }}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormControl.Label>Translation service</FormControl.Label>
                      <Select
                        name="optionSelect"
                        id="optionSelect"
                        value={this.state.translationService}
                        onChange={async (e: ChangeEvent<HTMLSelectElement>) => {
                          let value: string = e.target.value;
                          await this.handleFieldChange('translationService', value);
                        }}
                      >
                        <Select.Option key="0" value="">
                          Select an option
                        </Select.Option>
                        {translatorOptions.map((option: string) => {
                          return (
                            <Select.Option key={option} value={option}>
                              {option}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {this.state.showAPIKey && (
                      <FormControl>
                        <FormControl.Label>API key</FormControl.Label>
                        <TextInput
                          name="apiKey"
                          id="apiKey"
                          value={this.state.apiKey}
                          onChange={(e) => {
                            // @ts-ignore
                            this.handleFieldChange('apiKey', e.target.value as string);
                          }}
                        />
                      </FormControl>
                    )}
                    {this.state.showSandbox && (
                      <>
                        <FormControl>
                          <FormControl.Label>Sandbox</FormControl.Label>
                          <Switch
                            id="sandbox"
                            isChecked={this.state.sandbox}
                            onChange={() => this.handleFieldChange('sandbox', !this.state.sandbox)}
                          />
                        </FormControl>
                        <FormControl>
                          <FormControl.Label>Program Selection</FormControl.Label>
                          <Switch
                            id="programSelection"
                            isChecked={this.state.programSelection}
                            onChange={() =>
                              this.handleFieldChange(
                                'programSelection',
                                !this.state.programSelection,
                              )
                            }
                          />
                        </FormControl>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
