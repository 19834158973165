import { LOCALIZATION_METHOD_OBJECT } from '../config/translationModel';
import migration from './migration';
import { Bugsnag } from '../utils/bugsnag';

export default class upgrade1_1 extends migration {
  public runMigration = async (): Promise<void> => {
    const sdk = this.sdk;
    try {
      this.startUpdateAlert().then(async () => {
        sdk.cma.contentType
          .get({ contentTypeId: sdk.contentType.sys.id })
          .then((contentType: any) => {
            contentType.fields.push(LOCALIZATION_METHOD_OBJECT);
            return sdk.cma.contentType.update({ contentTypeId: contentType.sys.id }, contentType);
          })
          .then(() => {
            this.updateUser('Update complete. Please refresh the page to continue.');
          });
      });
    } catch (error: any) {
      console.log(error);
      Bugsnag.notify(new Error(error.message));
      this.finishWithErrorAlert();
    }
  };
}
