import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import { HelpCircleIcon } from '@contentful/f36-icons';
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Paragraph,
  Switch,
  Tooltip,
} from '@contentful/f36-components';
import { Logger } from '../../../utils/logger';

interface Props {
  sdk: EditorExtensionSDK;
  embeddedReference: boolean;
  selectedEmbeddedReferences: string[];
  handleFieldChange: Function;
}

interface State {
  canShow: boolean;
}

export default class EmbeddedReference extends React.Component<Props, State> {
  detachValueChangeHandler = () => {};
  state: State = {
    canShow: false,
  };

  logger = Logger.getInstance();

  async componentDidMount() {
    this.detachValueChangeHandler = this.props.sdk.entry.fields['contentType'].onValueChanged(
      this.handleContentTypeChange,
    );
    await this.handleMount();
  }

  handleMount = async () => {
    await this.handleContentTypeChange();
  };

  componentWillUnmount() {
    this.detachValueChangeHandler();
  }

  handleContentTypeChange = async () => {
    const contentType = this.props.sdk.entry.fields.contentType.getValue();
    this.setState({
      canShow: contentType && contentType !== 'mediaAssets',
    });
  };

  toggleField = async (field: 'embeddedReference') => {
    const value = !this.props[field];
    this.logger.info({
      event: `${field} field changed`,
      action: `${field} set to [${value ? 'enabled' : 'disabled'}]`,
    });
    this.props.handleFieldChange(field, value);
  };

  updateSelected = (value: string) => {
    let selectedEmbeddedReferences = this.props.selectedEmbeddedReferences;
    if (selectedEmbeddedReferences.includes(value)) {
      selectedEmbeddedReferences = selectedEmbeddedReferences.filter((field: string) => {
        return field != value;
      });
    } else {
      selectedEmbeddedReferences.push(value);
    }
    this.props.handleFieldChange('selectedEmbeddedReferences', selectedEmbeddedReferences);
  };

  render() {
    let embeddedMap = [
      {
        id: 'embedded-entry-block',
        name: 'Entry',
        enabled: true,
      },
      {
        id: 'embedded-entry-inline',
        name: 'Inline entry',
        enabled: true,
      },
      {
        id: 'embedded-asset-block',
        name: 'Asset',
        enabled: true,
      },
    ];
    return (
      <>
        {this.state.canShow && (
          <FormControl>
            <FormLabel htmlFor="embeddedReference">{'Rich-text embedded content'}</FormLabel>
            <Flex justifyContent="space-between">
              <FormControl style={{ display: 'inline-flex' }} className="no-margin">
                <Switch
                  id={'pro-lo-ref'}
                  isChecked={this.props.embeddedReference}
                  onChange={async () => {
                    await this.toggleField('embeddedReference');
                  }}
                />{' '}
                <FormControl.Label style={{ paddingRight: '5px', paddingLeft: '10px' }}>
                  Process rich-text embeds
                </FormControl.Label>
                <Tooltip
                  content={
                    'Enabling this will include embeds of the rich text fields which ever is selected below.'
                  }
                >
                  <HelpCircleIcon className={'tooltip-icon'} variant={'muted'} />
                </Tooltip>
              </FormControl>
            </Flex>
            {this.props.embeddedReference && (
              <div className="embedded-reference-container">
                <Paragraph>Select embed types the app should translate</Paragraph>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                  }}
                >
                  {embeddedMap.map((field: any) => {
                    return (
                      <span key={field.id}>
                        <Checkbox
                          value={field.id}
                          isChecked={this.props.selectedEmbeddedReferences.includes(field.id)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            this.logger.info({
                              event: `EmbededReferences field changed`,
                              action: `${field.name} [${this.props.selectedEmbeddedReferences.includes(field.id) ? 'excluded' : 'included'}]`,
                            });
                            this.updateSelected(e.target.value);
                          }}
                          isDisabled={!field.enabled}
                          id={field.id}
                        >
                          {field.name}
                        </Checkbox>
                        &nbsp;
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </FormControl>
        )}
      </>
    );
  }
}
