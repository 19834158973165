import { SOURCE_CONTENT, SOURCE_CONTENT_OBJECT } from '../config/translationModel';
import {
  EditorExtensionSDK,
  KnownSDK,
  PageExtensionSDK,
  SidebarExtensionSDK,
} from '../extensions-sdk';

import { bugsnag as Bugsnag } from '../utils/bugsnag';
import localClient from '../utils/localClient';
import migration from './migration';

type ExtensionSDK = EditorExtensionSDK | SidebarExtensionSDK | PageExtensionSDK;
const REQUEST_PROCESS_LIMIT = 10;
const REQUEST_LIMIT = 10;

const errorMessage =
  'There was an error updating the app to the latest version. Please reload the page and try again.';

export const sourceContentObject: any = {
  id: SOURCE_CONTENT,
  name: 'Source Content',
  type: 'Object',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};
export const oldSourceContentObject: any = {
  id: 'sourceContent',
  name: 'Source content',
  type: 'Array',
  localized: false,
  required: true,
  validations: [],
  disabled: false,
  omitted: false,
  items: {
    type: 'Link',
    validations: [],
    linkType: 'Entry',
  },
};

export const getAllTranslationProjects = async (
  sdk: ExtensionSDK,
  contentTypeId: string,
  uniqueIdentifier: string,
) => {
  // get all translation projects in the space
  let skip = 0;
  let total = 1;
  let limit = REQUEST_LIMIT;
  let translationProjectIdsWithEntryData: { [k: string]: any } = {};
  let processingLimit = 0;
  do {
    try {
      const entryData: any = await sdk.cma.entry.getMany({
        query: {
          content_type: contentTypeId,
          skip: skip,
          limit: limit,
        },
      });
      entryData.items.forEach((item: any) => {
        translationProjectIdsWithEntryData[item.sys.id] = item;
      });
      total = entryData.total;
      skip += REQUEST_LIMIT;
      processingLimit = 0;
    } catch (e) {
      processingLimit += 1;
      if (processingLimit == REQUEST_PROCESS_LIMIT) {
        sdk.notifier.error(
          'There was an error updating the app to latest version. Unable to fetch translation projects. Please try again.',
        );
        throw Error('Unable to fetch to translation projects');
      }
    }
  } while (skip < total && processingLimit < REQUEST_PROCESS_LIMIT);
  // Backup existing translation project entries in the env to s3
  storeS3Data(uniqueIdentifier, translationProjectIdsWithEntryData);
  return translationProjectIdsWithEntryData;
};

const getS3Data = async (key: string) => {
  let downloadURL = await localClient.getDownloadURL(key);
  let data = null;
  try {
    let response = await fetch(downloadURL);
    if (response.status == 200) {
      data = await response.json();
    }
  } catch (e: any) {
    console.error('Get S3 Error: ' + e.message);
  }
  return data;
};

const storeS3Data = async (key: string, data: any) => {
  try {
    let url = await localClient.getUploadURL(key);
    return await fetch(url, { method: 'PUT', body: JSON.stringify(data) });
  } catch (e: any) {
    console.error('Store S3 Error: ' + e.message);
  }
};

export const upgradeSourceContent = async (sdk: ExtensionSDK, loadingText: Function) => {
  debugger;
  loadingText('App Upgrade Started..');
  startUpdateAlert(sdk);
  // @ts-ignore
  const contentTypeId = sdk?.parameters?.installation?.translationsModelId;
  // create a unique identifier based on the space env and project id use this to store data
  let uniqueIdentifier = `${contentTypeId}--${sdk.ids.space}--${sdk.ids.environment}`;

  let dataUniqueId = 'data-' + uniqueIdentifier;
  let processedUniqueId = 'processed-' + uniqueIdentifier;

  let translationProjectIdsWithEntryData: any = {};
  let updatedProjectIds: Array<string> = []; // keep track of updated projects

  /** check if data is stored in s3, get it from there. **/
  loadingText('Backing up data..');
  let response = await getS3Data(dataUniqueId);
  if (response) {
    translationProjectIdsWithEntryData = response;
    let processedResponse = await getS3Data(processedUniqueId);
    if (processedResponse) {
      updatedProjectIds = processedResponse;
    }
  } else {
    translationProjectIdsWithEntryData = await getAllTranslationProjects(
      sdk,
      contentTypeId,
      dataUniqueId,
    );
  }

  const translationProjectsCount = Object.keys(translationProjectIdsWithEntryData).length;

  loadingText('Updating content model..');
  let updatedSourceContent = await updateSourceContentField(sdk, updatedProjectIds, contentTypeId);

  /** updating the entries - translation Projects **/
  let processingLimit = 0;
  let lastProject = '';
  if (updatedSourceContent) {
    do {
      try {
        for (const project in translationProjectIdsWithEntryData) {
          let entryData = Object.assign({}, translationProjectIdsWithEntryData[project]);
          if (!updatedProjectIds.includes(project)) {
            // fetch the entry before updating if it failed on last project
            if (lastProject == project && processingLimit > 0) {
              let currentEntry: any = await sdk.cma.entry.get({ entryId: project });
              entryData.sys = currentEntry.sys;
            }
            lastProject = project;
            await sdk.cma.entry.update({ entryId: entryData.sys.id }, entryData);
            updatedProjectIds.push(project);
            loadingText(
              `Updated ${updatedProjectIds.length} of ${translationProjectsCount} projects`,
            );
            processingLimit = 0;
            Bugsnag.leaveBreadcrumb('Successfully Updated ' + project);
          }
        }
      } catch (e: any) {
        processingLimit += 1;
        console.log('Error occurred:' + e.message);
      }
    } while (
      processingLimit < REQUEST_PROCESS_LIMIT &&
      updatedProjectIds.length < translationProjectsCount
    );
  }

  if (updatedProjectIds.length == translationProjectsCount && updatedSourceContent) {
    finishAlert(sdk);
    // clear out the stored data
    storeS3Data(dataUniqueId, {});
  } else {
    finishWithErrorAlert(sdk);
    // store the processed entries so that when we retry we pickup from where we left
    storeS3Data(processedUniqueId, updatedProjectIds);
    await updateSourceContentField(sdk, updatedProjectIds, contentTypeId, true);
    Bugsnag.notify(new Error('Unable to update to 1.3'));
  }
  return;
};

/** Update the content type **/
let updateSourceContentField = async (
  sdk: KnownSDK,
  updatedProjectIds: any,
  contentTypeId: any,
  revert = false,
) => {
  let contentType: any;
  let processingLimit = 0;
  let isProcessComplete: boolean = false;
  let sourceContentStructure = sourceContentObject;
  if (revert) {
    sourceContentStructure = oldSourceContentObject;
  }
  while (
    !isProcessComplete &&
    processingLimit < REQUEST_PROCESS_LIMIT &&
    updatedProjectIds.length == 0
  ) {
    try {
      contentType = await sdk.cma.contentType.get({ contentTypeId: contentTypeId });
      // Set omitted to true for source content field
      contentType.fields = contentType.fields.map((field: any) => {
        if (field.id == SOURCE_CONTENT) field.omitted = true;
        return field;
      });
      Bugsnag.leaveBreadcrumb('Omitted the field');
      await sdk.cma.contentType.update({ contentTypeId: contentType.sys.id }, contentType);

      // Get contentType with updated version
      contentType = await sdk.cma.contentType.get({ contentTypeId: contentTypeId });

      // Set deleted to true for source content field
      contentType.fields = contentType.fields.map((field: any) => {
        if (field.id == SOURCE_CONTENT) field.deleted = true;
        return field;
      });
      await sdk.cma.contentType.update({ contentTypeId: contentType.sys.id }, contentType);
      Bugsnag.leaveBreadcrumb('Marked field as deleted');

      contentType = await sdk.cma.contentType.get({ contentTypeId: contentTypeId });
      contentType.fields.push(sourceContentStructure);
      await sdk.cma.contentType.update({ contentTypeId: contentType.sys.id }, contentType);
      Bugsnag.leaveBreadcrumb('Updated the field type');
      isProcessComplete = true;
    } catch (e) {
      console.log('Error: ' + e);
      processingLimit += 1;
    }
  }

  if (!isProcessComplete) {
    sdk.notifier.error(errorMessage);
    Bugsnag.notify(new Error('Failed to updated content type'));
    return false;
  }
  return true;
};

let startUpdateAlert = async (sdk: any) => {
  sdk.dialogs.openAlert({
    title: 'Updating App to the latest version',
    message:
      'The App is being updated to the latest version. This may take a few minutes. ' +
      'Please do not refresh or close the tab',
    confirmLabel: 'Ok',
  });
};

let finishAlert = async (sdk: any) => {
  sdk.dialogs.openAlert({
    title: 'Translations app updated',
    message:
      'The app has been updated to the latest version. Please reload the page before continuing.',
  });
};

let finishWithErrorAlert = async (sdk: any) => {
  sdk.dialogs.openAlert({
    title: 'Translations app failed to update',
    message: errorMessage,
  });
};
