export const OPTION_LOCAL = 'Export/Import';
export const OPTION_ACCLARO = 'Acclaro';
export const OPTION_GOOGLE = 'Google';

interface translatorFieldType {
  id: string;
  name: string;
  type: string;
  localized: boolean;
  required: boolean;
  validations?: [];
  disabled: boolean;
  omitted: boolean;
}

export const translatorOptions = [OPTION_LOCAL, OPTION_ACCLARO /*OPTION_GOOGLE*/];
export const PROGRAM_SELECTION = 'programSelection';
export const PROGRAM_SELECTION_OBJECT: translatorFieldType = {
  id: PROGRAM_SELECTION,
  name: 'Program Selection',
  type: 'Boolean',
  localized: false,
  required: false,
  validations: [],
  disabled: false,
  omitted: false,
};

export default function (name: string = 'Translator', id: string = 'translator') {
  return {
    sys: {
      id: id,
      type: 'ContentType',
    },
    name: name,
    description: '',
    displayField: 'name',
    fields: [
      {
        id: 'name',
        name: 'Name',
        type: 'Symbol',
        localized: false,
        required: true,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: 'translationService',
        name: 'Translation service',
        type: 'Symbol',
        localized: false,
        required: true,
        validations: [
          {
            in: translatorOptions,
          },
        ],
        disabled: false,
        omitted: false,
      },
      {
        id: 'apiKey',
        name: 'API key',
        type: 'Text',
        localized: false,
        required: false,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: 'sandbox',
        name: 'Sandbox',
        type: 'Boolean',
        localized: false,
        required: false,
        validations: [],
        disabled: false,
        omitted: false,
      },
      {
        id: 'translatorInfo',
        name: 'Translator info',
        type: 'Object',
        localized: false,
        required: false,
        validations: [],
        disabled: true,
        omitted: true,
      },
      PROGRAM_SELECTION_OBJECT,
    ],
  };
}
