import React from 'react';
import { Spinner, Card, TextLink, Paragraph } from '@contentful/f36-components';
import { ExternalLinkIcon } from '@contentful/f36-icons';
import loGet from 'lodash.get';
import localClient from '../../utils/localClient';
import { dateTimeFormat } from '../../utils/helpers';
import { Row, Col } from 'react-grid-system';

const parseString = require('xml2js').parseString;

/**
 * ForLater: This component can show an error message if the feed is not valid
 */
export default class AcclaroNews extends React.Component {
  state: any = {
    news: [],
    loading: true,
  };

  async componentDidMount() {
    let xml = await localClient.fetchAcclaroNews();

    this.setState({
      loading: false,
    });
    parseString(xml, (err: any, result: any) => {
      if (err) {
        return;
      }
      this.setState({
        loading: false,
        news: loGet(result, 'rss.channel.0.item'),
      });
    });
  }

  render() {
    return (
      <>
        <Card className={'acclaro-news'}>
          <h3>Acclaro News</h3>
          {this.state.loading && (
            <div style={{ textAlign: 'center' }}>
              <Spinner></Spinner>
            </div>
          )}
          {!this.state.loading && (
            <>
              {this.state.news.map((newsItem: any, index: number) => {
                return (
                  <Row className={'news-item'} key={index}>
                    <Col xs={8}>
                      <TextLink variant={'secondary'} href={newsItem.link}>
                        {' '}
                        {newsItem.title}
                      </TextLink>
                    </Col>
                    <Col xs={4}>
                      <Paragraph>
                        {dateTimeFormat(new Date(newsItem.pubDate)).replace(/, \d+:.*M$/, '')}
                      </Paragraph>
                    </Col>
                  </Row>
                );
              })}
              {!this.state.loading && !(this.state.news.length > 0) && (
                <Paragraph>Couldn't load latest news from Acclaro.</Paragraph>
              )}
            </>
          )}
          <div style={{ textAlign: 'right' }}>
            <TextLink
              target={'_blank'}
              href={'https://www.acclaro.com/translation-blog/'}
              rel="noopener noreferrer"
              icon={<ExternalLinkIcon />}
            >
              Visit Acclaro Blog
            </TextLink>
          </div>
        </Card>
      </>
    );
  }
}
